// Render Prop
import React from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import Message from "./Message";
import "./scss/form.scss"

class EmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitSuccess: false,
      submitError: false,
      formErrorMessage: ''
    };
  }

  encodeFormValues = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  render() {
    return <div>

      <header className={'form-header'}>
        {this.props.formHeadingImg &&
        <div className={'form-header-image'} style={{backgroundImage: `url(${this.props.formHeadingImg})`}} />}
        <h3>{this.props.formHeading}</h3>
      </header>

      <Formik
        initialValues={{
          email: '',
          first_name: '',
          last_name: ''
        }}
        validate={values => {
          const errors = {};
          if (!values.first_name) {
            errors.first_name = 'Your first name is required';
          }
          if (!values.last_name) {
            errors.last_name = 'Your last name is required';
          }
          if (!values.email) {
            errors.email = 'Your email address is required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Provide a valid email address';
          }

          return errors;
        }}
        onSubmit={(values, {setSubmitting, resetForm}) => {
          this.setState({formErrorMessage: ''});
          this.setState({submitError: false});
          this.setState({submitSuccess: false});

          // A generic error message to show in case of an unspecified error
          const errorMsg = "Something went wrong. It's possible that your browser is blocking this form: try to temporarily disable any tracker/ad-blockers, and submit the form again. Contact support@nationalobserver.com if the error persists.";

          fetch("/", {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: this.encodeFormValues({"form-name": this.props.formName, ...values})
          })
          .then(() => {
            this.setState({submitSuccess: true})
            resetForm({})
          })
          .catch(() => {
            this.setState({submitError: true, formErrorMessage: errorMsg})
          })
          .finally(() => setSubmitting(false))

        }}
      >
        {({isSubmitting}) => (

          <div>
            {this.state.submitError && <Message type={"error"} text={this.state.formErrorMessage}/>}

            {this.state.submitSuccess && <Message type={"success"} text={this.props.successMessage}/>}

            {(!this.state.submitSuccess &&
              this.props.formIntro) &&
            <div className={"form-intro"}>
              {this.props.formIntro}
            </div>
            }
            {!this.state.submitSuccess &&
            <Form loading={"isSubmitting"} className={"form"} name={this.props.formName} data-netlify={true} netlify-honeypot="bot-snack">
              <div className="is-hidden">
                <label>Don’t fill this out if you're human: <input name="bot-snack"/></label>
              </div>
              <div className={"form-wrapper form-wrapper--horizontal"}>
                <div className={"form-wrapper__label"}>Name<span className={"required"}>*</span></div>
                <div className={"form-wrapper__inner"}>
                  <div className={"form-element form-element--text"}>
                    <label htmlFor="name_first" className={"is-sr-only"}>First name</label>
                    <Field type="text" name="first_name" placeholder={"First name"}/>
                    <ErrorMessage name="first_name" component="div" className={"form-error"}/>
                  </div>
                  <div className={"form-element form-element--text"}>
                    <label htmlFor="name_last" className={"is-sr-only"}>Last name</label>
                    <Field type="text" name="last_name" placeholder={"Last name"}/>
                    <ErrorMessage name="last_name" component="div" className={"form-error"}/>
                  </div>
                </div>
              </div>
              <div className={"form-element form-element--email"}>
                <label htmlFor="email">Email<span className={"required"}>*</span></label>
                <Field type="email" name="email"/>
                <ErrorMessage name="email" component="div" className={"form-error"}/>
              </div>
              <div className={"form-element form-element--text form-element--organisation"}>
                <label htmlFor="organisation">Organisation</label>
                <Field type="text" name="organisation"/>
              </div>
              <div className={"form-element form-element--textarea form-element--comments"}>
                <label htmlFor="comments">Comments</label>
                <Field component="textarea" name="comments"/>
              </div>
              <div className={"form-actions-wrapper"}>
                <div className={"form-actions"}>
                  <button type="submit" id="email-form-submit" disabled={isSubmitting}
                          className="button is-medium is-rounded is-primary is-size-5 has-text-weight-bold">
                    {this.props.buttonText}
                  </button>
                </div>
                <div className={"form-footer"}>
                  Your privacy is important to us. Your information will never be shared, rented or sold to a third
                  party.
                </div>
              </div>
            </Form>
            }
          </div>
        )}
      </Formik>

    </div>
  }

}

export default EmailForm;